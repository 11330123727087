<template>
  <main>
    <page-hero site="Kontakt"/>
      <section class="contact-section">
          <div class="container">

              <div class="row mb-5">
                  <div class="col-md-12 text-center">
                      <h2><i class="fa fa-envelope"></i> Formularz kontaktowy</h2>
                  </div>
              </div>

              <div class="row">
                  <div class="col-md-12">
                      <b-alert v-model="success" variant="success" dismissible>
                          Wiadomość została wysłana pomyślnie.
                      </b-alert>
                      <b-alert v-model="error" variant="danger" dismissible>
                          {{ errMsg }}
                      </b-alert>
                  </div>
              </div>

              <div class="row">
                  <div class="col-md-12">
                      <form @submit.prevent="handleForm">
                          <div class="row">
                              <div class="col-md-6">
                                  <div class="form-group">
                                      <label for="email">Email</label>
                                      <input
                                            id="email"
                                            v-model="email"
                                            class="form-control"
                                            :class="{ 'is-invalid': $v.email.$error }"
                                            placeholder="supermail@gmail.com"
                                            type="email"
                                      />
                                      <div v-if="$v.email.$error" class="invalid-feedback">
                                          <span v-if="!$v.email.required">Email jest wymagany.</span>
                                          <span v-if="!$v.email.email">To musi być prawidłowy adres email.</span>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-md-6">
                                  <div class="form-group">
                                      <label for="nick">Twój nick</label>
                                      <input
                                          id="nick"
                                          v-model="nick"
                                          class="form-control"
                                          :class="{ 'is-invalid': $v.nick.$error }"
                                          placeholder="RazorMeister"
                                          type="text"
                                      />
                                      <div v-if="$v.nick.$error" class="invalid-feedback">
                                          <span v-if="!$v.nick.required">Nick jest wymagany.</span>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div class="row">
                              <div class="col-md-12">
                                  <div class="form-group">
                                      <label for="title">Temat</label>
                                      <input
                                          id="title"
                                          v-model="title"
                                          class="form-control"
                                          :class="{ 'is-invalid': $v.title.$error }"
                                          placeholder="Potrzebna pomoc"
                                          type="text"
                                      />
                                      <div v-if="$v.title.$error" class="invalid-feedback">
                                          <span v-if="!$v.title.required">Temat jest wymagany.</span>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div class="row">
                              <div class="col-md-12">
                                  <div class="form-group">
                                      <label for="message">Wiadomość</label>
                                      <textarea
                                          id="message"
                                          v-model="message"
                                          class="form-control"
                                          :class="{ 'is-invalid': $v.message.$error }"
                                          placeholder="Piszę tę wiadomość w sprawie zaginiętego psa..."
                                      />
                                      <div v-if="$v.message.$error" class="invalid-feedback">
                                          <span v-if="!$v.message.required">Wiadomość jest wymagana.</span>
                                          <span v-if="!$v.message.minLength">Wiadomość musi mieć co najmniej 20 znaków.</span>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div class="row mt-5">
                              <div class="col-md-12 d-flex justify-content-center">
                                  <button class="btn primary-button" :disabled="loading">
                                      <span v-if="!loading"><i class="fa fa-save"></i> Wyślij wiadomość</span>
                                      <span v-else>
                                          <i class="fa fa-save"></i>
                                          <b-spinner class="mx-4" small label="Loading..."/>
                                      </span>
                                  </button>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </section>
  </main>
</template>

<script>
import {required, email, minLength} from "vuelidate/lib/validators";
import MainService from "../services/main.service";

export default {
    name: "Administration",
    data() {
        return {
            email: "",
            nick: "",
            title: "",
            message: "",
            loading: false,
            error: false,
            success: false,
            errMsg: ""
        }
    },
    validations: {
        email: {required: required, email: email},
        nick: {required: required},
        title: {required: required},
        message: {required: required, minLength: minLength(20)},
    },
    methods: {
        handleForm() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            } else {
                this.loading = true;

                MainService.sendEmail({email: this.email, nick: this.nick, title: this.title, message: this.message}).then(
                    () => {
                        this.success = true;
                        this.loading = false;
                        this.errMsg = "";
                        this.error = false;
                        this.email = "";
                        this.nick = "";
                        this.title = "";
                        this.message = "";
                        this.$v.$reset();
                    },
                    error => {
                        let err = "Undefined error";

                        if (error.response && error.response.data && error.response.data.message)
                            err = error.response.data.message;
                        else if (error.message)
                            err = error.message;
                        else if (error.toString())
                            err = error.toString();

                        console.log(error);
                        this.success = false;
                        this.errMsg = err;
                        this.error = true;
                        this.loading = false;
                    }
                )

                setTimeout(() => this.loading = false, 2000);
            }
        }
    }
}
</script>

<style scoped>

</style>